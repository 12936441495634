/* vietnamese */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/07b30c88f8c1c1ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a1b64b733866887d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/34a9823ac715e2c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/07b30c88f8c1c1ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a1b64b733866887d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/34a9823ac715e2c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/07b30c88f8c1c1ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a1b64b733866887d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/34a9823ac715e2c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/07b30c88f8c1c1ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a1b64b733866887d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/34a9823ac715e2c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/07b30c88f8c1c1ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1b64b733866887d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quicksand_6e34fe';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/34a9823ac715e2c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Quicksand_Fallback_6e34fe';src: local("Arial");ascent-override: 94.93%;descent-override: 23.73%;line-gap-override: 0.00%;size-adjust: 105.35%
}.__className_6e34fe {font-family: '__Quicksand_6e34fe', '__Quicksand_Fallback_6e34fe', system-ui, Arial, sans-serif;font-style: normal
}.__variable_6e34fe {--font-quicksand: '__Quicksand_6e34fe', '__Quicksand_Fallback_6e34fe', system-ui, Arial, sans-serif
}

